/*
* 验证身份证号
* */
export function checkIdNum(rule, value, callback) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    if (!value) {
        return callback(new Error('身份证件号码不能为空'))
    } else if (!reg.test(value)) {
        return callback(new Error('身份证件号码不正确'))
    } else {
        callback()
    }
}

/*
* 验证手机号
* */
export function checkTelNum(rule, value, callback) {
    const reg = /^1[0-9]{10}$/;
    if (!value) {
        return callback(new Error('手机号码不能为空'))
    } else if (!reg.test(value)) {
        return callback(new Error('手机号码不正确'))
    } else {
        callback()
    }
}


export default {
    idCard: [{required: true, validator: checkIdNum, trigger: 'blur'}],
    tel: [{required: true, validator: checkTelNum, trigger: 'blur'}],
    phone: {required: true, validator: checkTelNum, trigger: 'blur'},
    password: {pattern: '^(?=.*\\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,20}$', message: '密码需要包含数字及字母且8-20个字符', trigger: 'change'}

}

export const reg_phone_format = /(\d{3})\d{4}(\d{4})/

export function checkTel(value) {
    const reg = /^1[0-9]{10}$/;
    console.log(value)
    if (!value) {
        return false
    } else if (!reg.test(value)) {
        return false
    } else {
        return true
    }
}