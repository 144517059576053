<template>
    <div class="header-container">
        <div class="header">
            <img src="../assets/img/logo.png" alt="" class="logo">
            <div class="home flex align-center">
                <a class="operate" :href="url">{{back}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {service_phone} from "@/utils/utils";
    export default {
        components: {},
        name: "headers",
        data() {
            return {
                service_phone:service_phone
            }
        },
        props:{
            url:{
                type:String,
                default:''
            } ,
            back:{
                type:String,
                default:'返回首页'
            }
        },
        created() {

        },
        methods: {}
    }
</script>

<style scoped lang="scss">
    .header-container {
        background-color: #0a2240;
        box-shadow: 0px 6px 18px 1px
        rgba(10, 34, 64, 0.45);
        width: 100%;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1200px;
            height: 100px;
            margin: 0 auto;
            .logo {
                margin: 20px 0;
                height: 50px;
            }
            .home {
                .operate {
                    padding: 0 20px;
                    height: 40px;
                    background-color: #ff9600;
                    border-radius: 20px;
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
    }

</style>