import {qspost} from '@/plugins/axios'

/*
 * 机构登录用户登录
 * @constructor jack
 * userName	string	true  机构账号、机构手机号
 * passWord	string	true  机构的密码，MD5加密后传值
 * */
export const login = (userName,passWord) => qspost('/Home/Index/login', {userName,passWord})

/*
 * 发送短信验证码
 * @constructor jack
 * */
export const sendSms = (phoneNum) => qspost('/Home/Index/userSendSMS', {phoneNum})

/*
 * 用户密码登录
 * @constructor jack
 * phone string	true 手机号
 * code	 string	true 验证码
 * */
export const smsLogin = (phone,code) => qspost('/Home/Index/smsLogin', {phone,code})


/*
 * 易魔方跳转登录
 * @constructor jack
 * phone string	true 手机号
 * code	 string	true 验证码
 * */
export const emofLogin = (emofUserTokenSafer) => qspost('/Home/Index/emofLogin', {emofUserTokenSafer})


/*
 * 微信登录
 * @constructor jack
 * phone string	true 手机号
 * code	 string	true 验证码
 * */
export const wxLogin = (code) => qspost('/home/Index/wxLogin', {code})






