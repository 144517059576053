<template>
    <div class="login-warpper">
        <header-modular :url="web_emof_setting"></header-modular>
        <div class="login-container">
            <div class="login-info">
                <div class="classifys">
                    <div class="classify" :class="{active:type === 0}" @click="type = 0">帐号登录</div>
                    <div class="classify" :class="{active:type === 1}" @click="type = 1">快捷登录</div>
                </div>
                <div class="login" v-show="type === 0">
                    <el-form class="form" :model="loginForm" :rules="login_rules" ref="loginForm">
                        <el-form-item prop="phone">
                            <div class="login-input">
                                <i class="iconfont icon-phone"></i>
                                <el-input v-model="loginForm.phone" placeholder="请输入手机号"
                                          class="login-item"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item prop="password">
                            <div class="login-input">
                                <i class="iconfont icon-password"></i>
                                <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"
                                          class="login-item"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="login-button" @click="submitForm('loginForm')">立即登录</div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="login" v-show="type === 1">
                    <el-form class="form" :model="phoneForm" :rules="phone_rules" ref="phoneForm">
                        <el-form-item prop="phone">
                            <div class="login-input">
                                <i class="iconfont icon-phone"></i>
                                <el-input v-model="phoneForm.phone" maxlength="11" placeholder="请输入手机号"
                                          class="login-item"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item prop="code">
                            <div class="login-input">
                                <i class="iconfont icon-password"></i>
                                <el-input v-model="phoneForm.code" maxlength="11" placeholder="请输入验证码"
                                          class="login-item"></el-input>
                                <div class="code-text" @click="submitSMS">
                                    {{codeText}} <span v-if="typeof codeText === 'number'">秒后重新发送</span>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="login-button" @click="submitForm('phoneForm')">立即登录</div>
                        </el-form-item>
                    </el-form>
                </div>
<!--                <div class="login-icons">-->
<!--                    <div class="login-icon-t">-->
<!--                        <i class="left"></i>-->
<!--                        <span>使用其他方式登录</span>-->
<!--                        <i class="right"></i>-->
<!--                    </div>-->
<!--                    <div class="login-img">-->
<!--                        <a href="https://open.weixin.qq.com/connect/qrconnect?appid=wx3f42f2fd6f63f468&redirect_uri=http://zzjz.emof.cn/login&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect"-->
<!--                           class="login-icon-wx">-->
<!--                            <i class="iconfont icon-wx"></i>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <p style="text-align: center;color: #fff;margin-top: 10px">微信登录</p>-->

<!--                </div>-->
            </div>
        </div>
        <footer-modular></footer-modular>
    </div>
</template>

<script>
    import rules from '@/utils/rules'
    import {web_emof_setting,getQueryString,md5} from '@/utils/utils'
    import {login,sendSms,smsLogin,wxLogin} from "@/api/login";
    import FooterModular from "@/components/FooterLinkModular.vue";
    import HeaderModular from "@/components/HeaderModular.vue";
    export default {
        name: "login",
        data() {
            return {
                type: 0,  //  登录类型
                loginForm: {
                    phone: '',
                    password: ''
                },
                login_rules: {
                    phone: {required: true, message: '请输入帐号/手机号', trigger: 'change'},
                    password: [
                        {required: true, message: '请输入密码', trigger: 'change'}
                    ]
                },
                phoneForm: {
                    phone: '',
                    code: '',
                },
                phone_rules: {
                    phone: rules.tel,
                    code: [
                        {required: true, message: '请输入验证码', trigger: 'change'}
                    ],
                },
                codeText: '获取验证码', // 验证码文字
                id: null, // 计时器id
                timeId: null, // 一次性计时器id
                web_emof_setting: web_emof_setting,

                wx_code: '',
            }
        },
        created() {
            this.wx_code = getQueryString('code')
            if (this.wx_code) {
                this.wxLogin()
            }
        },
        components: {FooterModular, HeaderModular},
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.login(formName)
                    }
                });
            },
            async login(formName) {
                const loading = this.$loading({
                    lock: true,
                    text: '登录中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let _data =formName === 'loginForm' ?
                    await login(this.loginForm.phone, md5(this.loginForm.password)) :
                    formName === 'phoneForm' ?
                        await smsLogin(this.phoneForm.phone, this.phoneForm.code) :
                        await wxLogin(this.wx_code)
                loading.close();
                if (_data.status === 200) {
                    this.$store.state.emofUserTokenSafer = _data.data.emofUserTokenSafer
                    this.$store.state.user = _data.data
                    await window.localStorage.setItem('user', JSON.stringify(_data.data))
                    await window.localStorage.setItem('emofUserTokenSafer', _data.data.emofUserTokenSafer)
                    this.$router.replace('/organiz')
                }else {
                    this.$confirm(_data.msg, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                }
            },
            submitSMS() {
                this.$refs.phoneForm.validateField('phone', (valid) => {
                    if (!valid) {
                        this.sendCode()
                    }
                })
            },
            async sendCode() {
                if (this.codeText === '获取验证码' || this.codeText === '重新发送') {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在发送...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let _data = await sendSms(this.phoneForm.phone)
                    loading.close();
                    if (_data.status === 200) {
                        let codeNum = 60
                        this.id = setInterval(() => {
                            if (codeNum === 0) {
                                this.codeText = '重新发送'
                                clearInterval(this.id)
                                return
                            }
                            if (typeof codeNum === 'number') {
                                codeNum--
                                this.codeText = codeNum
                            }
                        }, 1000)
                    } else {
                        this.$message.error(_data.msg)
                        this.codeText = '重新发送'
                        clearInterval(this.id)
                    }
                }
            },
            // 微信登录
            async wxLogin() {
                this.login('wxForm')
            }
        },
        filters: {},
        watch: {
            type() {
                this.$refs.phoneForm.clearValidate()
                this.$refs.loginForm.clearValidate()
            }
        }
    }
</script>

<style scoped lang="scss">

    .login-warpper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        .login-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
            width: 100%;
            background-size: cover;
            background: url(../assets/img/bg_login.jpg) no-repeat center;;
            background-size: 100%;
            .login-info {
                display: flex;
                flex-direction: column;
                width: 400px;
                min-height: 500px;
                background-color: #0a2240;
                border-radius: 10px;
                position: relative;
                padding: 45px;
                margin-right: 150px;
                z-index: 1;
                &:after {
                    position: absolute;
                    width: 561px;
                    height: 357px;
                    background: rgba(10, 34, 64, .1);
                    border-radius: 10px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: '';
                    z-index: -1;
                }
                &:before {
                    position: absolute;
                    width: 489px;
                    height: 450px;
                    margin: 0 auto;
                    background: rgba(10, 34, 64, .3);
                    border-radius: 10px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: '';
                    z-index: -1;
                }
                .form {
                    margin-top: 50px;
                    .login-input {
                        display: flex;
                        align-items: center;
                        height: 48px;
                        border-radius: 4px;
                        border: solid 1px #ffffff;
                        padding: 0 20px;
                        i {
                            display: block;
                            text-align: center;
                            font-size: 20px;
                            color: #ffffff;
                        }
                        .login-item {
                            flex: 1;
                            color: #ffffff;
                        }

                        .code-img {
                            width: 116px;
                            height: 43px;
                            background: #fff;
                        }
                        .code-text {
                            flex-shrink: 0;
                            text-align: center;
                            line-height: 41px;
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
                .login-button {
                    width: 100%;
                    height: 46px;
                    background-image: linear-gradient(168deg,
                            #ffb400 0%,
                            #fc7802 100%),
                    linear-gradient(
                                    #ff9600,
                                    #ff9600);
                    background-blend-mode: normal,
                    normal;
                    border-radius: 10px;
                    text-align: center;
                    font-size: 18px;
                    line-height: 46px;
                    color: #ffffff;
                    cursor: pointer;
                    user-select: none;
                }
                .login-icons {
                    .login-icon-t {
                        font-size: 12px;
                        color: #999;
                        height: 36px;
                        text-align: center;
                        line-height: 36px;
                        margin-bottom: 30px;
                        .left {
                            width: 100px;
                            border-bottom: 1px solid rgba(255, 255, 255, .4);
                            float: left;
                            margin-top: 18px;
                        }
                        .right {
                            width: 100px;
                            border-bottom: 1px solid rgba(255, 255, 255, .4);
                            float: right;
                            margin-top: 18px;
                        }
                    }

                    .login-img {
                        width: 45px;
                        margin: 0 auto;
                        a.login-icon-wx {
                            display: inline-block;
                            width: 45px;
                            height: 45px;
                            background: #fff;
                            border-radius: 100%;
                            text-align: center;
                            line-height: 45px;
                            i {
                                font-size: 30px;
                                color: #0a2240;
                            }
                        }
                    }
                }
                .classifys {
                    display: flex;
                    margin-top: 15px;
                    position: relative;
                    &:before {
                        position: absolute;
                        width: 308px;
                        height: 1px;
                        background-color: #ffffff;
                        opacity: 0.4;
                        content: '';
                        bottom: -15px;
                        left: 0;
                    }
                    &:after {
                        position: absolute;
                        width: 1px;
                        height: 21px;
                        background-color: #ffffff;
                        opacity: 0.4;
                        content: '';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .classify {
                        font-size: 18px;
                        text-align: center;
                        flex: 1;
                        color: #ffffff44;
                        cursor: pointer;
                        &.active {
                            color: #ffffff;
                            position: relative;
                            &:before {
                                position: absolute;
                                width: 76px;
                                height: 2px;
                                background-color: #ffffff;
                                content: '';
                                bottom: -15px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
</style>