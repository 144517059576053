<template>
    <div class="warrper">
        <div class="foot-container flex flex-direction align-center">
            <div class="width-100 flex justify-between align-center">
                <div class="flex align-center">
                    <i class="service-icon el-icon-phone-outline"></i>
                    <div class="flex flex-direction margin-left-sm">
                        <div class="service-phone">服务电话：{{service_phone}}</div>
                        <div class="service-day">周一至周日8:30-17:30</div>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex flex-direction align-center">
                        <img src="../assets/img/erweima_zf.png" alt="" class="scan">
                        <div class="scan-tag">免费获取更多社会组织服务</div>
                    </div>
                    <div class="flex flex-direction align-center margin-left">
                        <img src="../assets/img/erweima_zc.png" alt="" class="scan">
                        <div class="scan-tag">了解更多信息化产品</div>
                    </div>
                </div>
            </div>
            <div class="info">
                Copyright © 知诚会 <a class="margin-left-xs" target="_blank" href="http://beian.miit.gov.cn">京ICP备17050284号-7</a><img class="beian" src="http://www.beian.gov.cn/img/new/gongan.png"><a class="margin-right-xs" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037085">京公网安备11010802037085号</a>  北京知诚社会组织众扶发展促进会  版权所有
            </div>

        </div>
    </div>
</template>
<script>
    import {service_phone} from "@/utils/utils";
    export default {
        name: "FooterModular",
        data() {
            return {
                service_phone:service_phone
            }
        },
        created() {
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .warrper {
        width: 100%;
        height: 173px;
        background-color: #0a2240;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .foot-container {
            width: 1200px;
            margin: 0 auto;
            .logo {
                margin: 20px 0;
                height: 40px;
            }
            .scan {
                width: 75px;
                height: 75px;
            }
            .scan-tag {
                font-size: 10px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                margin-top: 10px;
            }
            .service-icon{
                font-size: 27px;
                color: #ffffff;
            }
            .service-phone{
                font-size: 16px;
                color: #ffffff;
            }
            .service-day{
                font-size: 12px;
                color: #ffffff;
                opacity: 0.5;
            }
            .link {
                font-size: 16px;
                color: #ffffff;
            }
            .info {
                font-size: 12px;
                margin-top: 15px;
                color: #ffffff;
                .beian {
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                }

                a {
                    font-size: 12px;
                    color: #ffffff;
                }
            }
        }

    }
</style>